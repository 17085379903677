var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"BodyComponents"}},[_c('div',{ref:"top_ref",staticClass:"top_view"},[_c('div',{staticClass:"top_center"},[_vm._m(0),_c('ul',{staticClass:"top_Category_ul"},[_c('li',{class:'top_Category_ul_li' && _vm.click_type == 0
              ? 'top_Category_ul_li_click'
              : 'wei_click'},[_c('router-link',{class:'top_Category_ul_li' && _vm.click_type == 0 ? 'top_a_color' : '',attrs:{"to":""},nativeOn:{"click":function($event){return _vm.top_Category_click(0)}}},[_vm._v(" 微信小程序 ")])],1),_c('li',{class:'top_Category_ul_li' && _vm.click_type == 1
              ? 'top_Category_ul_li_click'
              : 'wei_click'},[_c('router-link',{class:'top_Category_ul_li' && _vm.click_type == 1 ? 'top_a_color' : '',attrs:{"to":""},nativeOn:{"click":function($event){return _vm.top_Category_click(1)}}},[_vm._v(" 南方航空 ")])],1),_c('li',{class:'top_Category_ul_li' && _vm.click_type == 2
              ? 'top_Category_ul_li_click'
              : 'wei_click'},[_c('router-link',{class:'top_Category_ul_li' && _vm.click_type == 2 ? 'top_a_color' : '',attrs:{"to":""},nativeOn:{"click":function($event){return _vm.top_Category_click(2)}}},[_vm._v(" 东方航空 ")])],1)]),(_vm.Terminal_type == 'pc')?_c('div',{staticClass:"top_right_search_view"},[_c('div',{staticClass:"top_right_search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_data),expression:"search_data"}],staticClass:"top_right_search_input",attrs:{"type":"text","placeholder":"姓名/票号"},domProps:{"value":(_vm.search_data)},on:{"input":function($event){if($event.target.composing)return;_vm.search_data=$event.target.value}}}),_c('button',{staticClass:"top_right_search_button",on:{"click":_vm.search}},[_c('van-icon',{staticStyle:{"cursor":"pointer"},attrs:{"name":"search"}})],1)])]):_vm._e(),_c('div',{staticClass:"top_right_nick_av"},[(_vm.Terminal_type == 'pc')?_c('div',{staticClass:"top_right_avatar_div"},[_c('img',{attrs:{"src":_vm.avatar,"alt":""}})]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.nickname))])]),_c('ul',{staticClass:"top_Secondary_directory"},_vm._l((_vm.top_Secondary_directory[this.click_type]),function(item,index){return _c('li',{key:index,attrs:{"index":index,"id":item.id}},[_c('router-link',{style:(item.id == _vm.level_second_id ? 'color:#19A4F3;' : ''),attrs:{"to":""},nativeOn:{"click":function($event){return _vm.level_second(index, item.id, item.name)}}},[_vm._v(_vm._s(item.text))])],1)}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_left_logo"},[_c('img',{attrs:{"src":"https://wx.yaolvxing.com/static/xcx/my_icont/basicprofile.jpg","alt":""}})])
}]

export { render, staticRenderFns }