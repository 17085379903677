import { render, staticRenderFns } from "./BodyComponents.vue?vue&type=template&id=5bfd0b2a&scoped=true&"
import script from "./BodyComponents.vue?vue&type=script&lang=js&"
export * from "./BodyComponents.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bfd0b2a",
  null
  
)

export default component.exports