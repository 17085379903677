<template>
  <div id="BodyComponents">
    <div class="top_view" ref="top_ref">
      <div class="top_center">
        <div class="top_left_logo">
          <img
            src="https://wx.yaolvxing.com/static/xcx/my_icont/basicprofile.jpg"
            alt=""
          />
        </div>
        <ul class="top_Category_ul">
          <li
            :class="
              'top_Category_ul_li' && click_type == 0
                ? 'top_Category_ul_li_click'
                : 'wei_click'
            "
          >
            <router-link
              @click.native="top_Category_click(0)"
              :class="
                'top_Category_ul_li' && click_type == 0 ? 'top_a_color' : ''
              "
              to
            >
              微信小程序
            </router-link>
          </li>
          <li
            :class="
              'top_Category_ul_li' && click_type == 1
                ? 'top_Category_ul_li_click'
                : 'wei_click'
            "
          >
            <router-link
              @click.native="top_Category_click(1)"
              :class="
                'top_Category_ul_li' && click_type == 1 ? 'top_a_color' : ''
              "
              to
            >
              南方航空
            </router-link>
          </li>
          <li
            :class="
              'top_Category_ul_li' && click_type == 2
                ? 'top_Category_ul_li_click'
                : 'wei_click'
            "
          >
            <router-link
              @click.native="top_Category_click(2)"
              :class="
                'top_Category_ul_li' && click_type == 2 ? 'top_a_color' : ''
              "
              to
            >
              东方航空
            </router-link>
          </li>
        </ul>
        <!-- 搜索 -->
        <div class="top_right_search_view" v-if="Terminal_type == 'pc'">
          <div class="top_right_search">
            <input
              type="text"
              v-model="search_data"
              class="top_right_search_input"
              placeholder="姓名/票号"
            />
            <button class="top_right_search_button" @click="search">
              <van-icon name="search" style="cursor:pointer "/>
            </button>
          </div>
        </div>
        <div class="top_right_nick_av">
          <div class="top_right_avatar_div" v-if="Terminal_type == 'pc'">
            <img :src="avatar" alt="">
          </div>
          <span>{{nickname}}</span>
        </div>
        <!-- 二级目录 -->
        <ul class="top_Secondary_directory">
          <li
            v-for="(item, index) in top_Secondary_directory[this.click_type]"
            :key="index"
            :index="index"
            :id="item.id"
          >
            <router-link
              :style="item.id == level_second_id ? 'color:#19A4F3;' : ''"
              to
              @click.native="level_second(index, item.id, item.name)"
              >{{ item.text }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BodyComponents",
  data() {
    return {
      click_type: sessionStorage.getItem("Primary_id"),
      top_Secondary_directory: {
        // 微信小程序二级目录
        0: [
          {
            id: "WeCaht_Seat_Order",
            name: "WeChatApplet",
            text: "南航选座订单",
          },
          {
            id: "MU_WeCaht_Seat_Order",
            name: "MU_WeChatApplet",
            text: "东航选座订单",
          },
        ],
        // 南航二级目录
        1: [
          {
            id: "Seat_Change",
            name: "SeatSelection",
            text: "选座低改高",
          },
          {
            id: "Southern_Change_Order",
            name: "SouthernOrder",
            text: "选座订单",
          },
        ],
        // 东航二级目录
        2: [
          {
            id: "Baggage_Allowance_Change",
            name: "Eastern_Allowance_Change",
            text: "行李额低改高",
          },
          {
            id: "Eastern_Seat_Selection",
            name: "Eastern_Seat_Selection",
            text: "东航选座",
          },
          {
            id: "Eastern_Seat_Order",
            name: "Eastern_Seat_Order",
            text: "选座订单",
          },
          {
            id: "Baggage_Change_Order",
            name: "Baggage_Change_Order",
            text: "行李额订单",
          },
          {
            id: "Eastern_Account_Number",
            name: "Eastern_Account_Number",
            text: "东航账号",
          },
          {
            id: "Eastern_Account_Daily_Record",
            name: "Eastern_Account_Daily_Record",
            text: "行李额下单日志",
          },
        ],
      },
      // 二级目录默认id
      level_second_id: sessionStorage.getItem("Secondary_id"),
      // 输入搜索数据
      search_data : '',
      avatar : sessionStorage.getItem('avatar'),
      nickname : sessionStorage.getItem('nickname'),
    };
  },
  props: {
    search_transmit_data : []
  },
  methods: {
    // 一级选项
    top_Category_click(e) {
      console.log(e);
      sessionStorage.setItem("Primary_id", e);
      sessionStorage.setItem(
        "Secondary_id",
        this.top_Secondary_directory[e][0].id
      );
      this.$router.push({
        name: this.top_Secondary_directory[e][0].name,
      });
    },
    // 二级目录
    level_second(index, id, name) {
      if (name == "Eastern_Seat_Order") {
        this.$message.error("无订单未开发");
        return false;
      }
      sessionStorage.setItem("Secondary_id", id);
      this.$router.push({
        name: name,
      });
    },
    // 数据搜索
    search(){

      console.log(this.$props.search_transmit_data)
    }
  },
  mounted() {
    // 获取当前屏幕高度
    if (!sessionStorage.getItem("table_height") || sessionStorage.getItem("table_height")<0) {
      let windows_height = document.body.clientHeight;
      let table = windows_height - this.$refs.top_ref.offsetHeight * 1.5 - 40;
      sessionStorage.setItem("table_height", table);
    }
  },
  beforeCreate() {
    if ((sessionStorage.getItem("Primary_id") ?? "") == "")
      sessionStorage.setItem("Primary_id", 0);
    if ((sessionStorage.getItem("Secondary_id") ?? "") == "")
      sessionStorage.setItem("Secondary_id", "WeCaht_Seat_Order");
  },
};
</script>
<style scoped>
</style>